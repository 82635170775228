import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MainSectionZakaznickaZona from "../components/zakaznicka-zona/mainSection.js";

import "../styles/programovanie-na-mieru.scss";
import ContactUs from "../components/contactUs";
import ProgramovanieSolutionBox from "../components/programovanie-na-mieru/programovanieSolutionBox";
import ZonaFaqQuestion from "../components/zakaznicka-zona/zonaFaqQuestion.js";
import SliderSectionZona from "../components/zakaznicka-zona/sliderSection.js";
import References from "../components/homepage/references.js";
import ZonaClankySlider from "../components/zakaznicka-zona/zonaClankySlider";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

import analyzaImg from "../images/vyvoj-it/vyvoj-it-analyza-a-zber-poziadaviek.jpg";
import dizajnImg from "../images/vyvoj-it/vyvoj-it-navrh-a-architektura-systemu.jpg";
import vyvojImg from "../images/vyvoj-it/proces-vyvoja-a-programovania-it-systemu.jpg";
import testovanieImg from "../images/vyvoj-it/vyvoj-it-faza-testovania.jpg";
import nasadenieImg from "../images/vyvoj-it/vyvoj-it-systemu-na-mieru-nasadenie-a-skolenie.jpg";
import podporaImg from "../images/vyvoj-it/vyvoj-it-a-programovanie-faza-udrzby-systemu.jpg";
import iteraciaImg from "../images/vyvoj-it/vyvoj-a-programovanie-it-systemu-faza-spatnej-vazby.jpg";

import skusenostiIcon from "../images/vyvoj-it/vyvoj-it-systemov-odbornost-a-skusenosti.svg";
import pristupIcon from "../images/vyvoj-it/vyvoj-it-softveru-osobny-pristup.svg";
import transparentnostIcon from "../images/vyvoj-it/programovanie-it-systemov-transparentnost.svg";
import kvalitaIcon from "../images/vyvoj-it/vyvoj-it-systemov-na-mieru-kvalita.svg";
import podporaIcon from "../images/vyvoj-it/softverove-riesenie-osobny-pristup-a-podpora.svg";
import inovacieIcon from "../images/vyvoj-it/programovanie-it-systemov-a-inovovanie.svg";
import doveryhodnostIcon from "../images/vyvoj-it/vyvoj-it-systemov-dovera-a-doveryhodnost.svg";
import komunikaciaIcon from "../images/vyvoj-it/vyvoj-a-programovanie-it-systemov-a-komunikacia.svg";
import partnerstvoIcon from "../images/vyvoj-it/vyvoj-it-systemov-dlhodobe-partnerstvo.svg";

//import zonesColage from "../images/zakaznicka-zona/okna.jpg";

export const pageQuery = graphql`
  query {
    articles: wpCategory(slug: { eq: "programovanie-na-mieru" }) {
      posts {
        nodes {
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              title
              gatsbyImage(width: 1920)
              sourceUrl
            }
          }
        }
      }
    }
    mysqlProjects(url: { eq: "plusim" }) {
      url
      real_url
      client
      hashtag
      project_description
      case_study_title
      case_study_rok
      case_study_sluzby
      case_study_vyzva
      case_study_proces
      case_study_riesenie
      ogDescription
    }
    files: allFile(
      filter: {
        relativeDirectory: { eq: "plusim" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    projekty: allMysqlProjects(sort: { fields: case_study_rok, order: DESC }) {
      nodes {
        url
        client
        case_study_description
      }
    }
    logosWhite: allFile(
      filter: {
        name: { eq: "logo-white" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        relativeDirectory
        publicURL
      }
    }
  }
`;

const VyvojItSystemov = (pageQuery) => {
  const images = {};
  pageQuery.data.files.nodes.map((node) => {
    let index = node.name;
    images[index] = node;
  });

  return (
    <Layout pageName="programovanie-na-mieru-main">
      <SEO
        title="Vývoj a programovanie IT systémov"
        description="Vývoj a programovanie IT systémov na mieru je u nás v rukách skúsených odborníkov, ktorí kombinujú moderné technológie s overenými prístupmi. Vždy sa zameriavame na individuálne potreby a prinášame riešenia, ktoré sú efektívne, spoľahlivé a škálovateľné. Vytvárame systémy, ktoré poháňajú vašu firmu dopredu a zabezpečujú jej konkurencieschopnosť."
      />
      <MainSectionZakaznickaZona
        heading="Vývoj a programovanie IT systémov"
        textHtml={
          <>
            <b>Vývoj a programovanie IT systémov</b> na mieru je u nás v rukách
            skúsených odborníkov, ktorí kombinujú <b>moderné technológie</b> s
            overenými prístupmi. Vždy sa zameriavame na individuálne potreby a
            prinášame riešenia, ktoré sú{" "}
            <b>efektívne, spoľahlivé a škálovateľné</b>. Vytvárame systémy,
            ktoré poháňajú vašu firmu dopredu a zabezpečujú jej
            konkurencieschopnosť.
          </>
        }
      />
      <section id="what-is-client-zone">
        <ProgramovanieSolutionBox
          img={3}
          heading="Skúsenosti z reálneho sveta"
          text={
            <>
              <b>S 9+ ročnými reálnymi skúsenosťami</b> v oblasti IT sme sa
              stali dôveryhodným partnerom pre mnohé firmy. Tieto roky nám
              umožnili hlboko pochopiť potreby klientov a vyvinúť{" "}
              <b>osvedčené metódy</b> v oblasti softvérovej vývoj. Naša dlhodobá
              prax z nás robí
              <b> dôveryhodného partnera</b> v poskytovaní stabilných a
              inovatívnych riešení.
            </>
          }
        />
      </section>

      {/*<section id="client-zone-colage">
        <img
          className="img"
          src={zonesColage}
          alt="Zákaznícke zóny"
          
        />
      </section>*/}

      <section className="section-blog">
        <div className="banner-blog">
          <h2>
            Vývoj a programovanie IT systémov a softvéru na mieru. Čo môžete
            očakávať?
          </h2>
        </div>
      </section>
      <section id="case-study-info">
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={skusenostiIcon}
                alt="Vývoj IT systémov odbornosť a skúsenosti"
                title="Vývoj IT systémov odbornosť a skúsenosti"
              />
            </div>
          </div>
          <div>
            <h3>
              Odbornosť a skúsenosti{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              S viac ako 9 rokmi v oblasti IT máme za sebou mnoho úspešných
              projektov. Každá nová výzva nás posúva vpred a umožňuje nám
              ponúknuť vám ešte lepšie riešenia.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={pristupIcon}
                alt="Vývoj IT softvéru osobný prístup"
                title="Vývoj IT softvéru osobný prístup"
              />
            </div>
          </div>
          <div>
            <h3>
              Osobný prístup{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Rozumieme, že každý projekt je jedinečný. Pracujeme ruka v ruke s
              vami, aby sme pochopili vaše konkrétne potreby a prispôsobili naše
              služby vašim požiadavkám.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={transparentnostIcon}
                alt="Programovanie IT systémov transparentnosť"
                title="Programovanie IT systémov transparentnosť"
              />
            </div>
          </div>
          <div>
            <h3>
              Transparentnosť{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Komunikujeme počas každého kroku procesu, aby sme zabezpečili
              jasnosť a dôveru. Ste vždy informovaní o pokroku a smerovaní
              projektu.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={kvalitaIcon}
                alt="Vývoj IT systémov na mieru kvalita"
                title="Vývoj IT systémov na mieru kvalita"
              />
            </div>
          </div>
          <div>
            <h3>
              Kvalita a spolahlivosť{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Venujeme značné úsilie zabezpečeniu najvyšších štandardov vo
              všetkom, čo robíme. Môžete sa spoľahnúť na to, že dostanete
              výsledky presne podľa svojich predstáv.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={podporaIcon}
                alt="Softvérové riešenie osobný prístup a podpora"
                title="Softvérové riešenie osobný prístup a podpora"
              />
            </div>
          </div>
          <div>
            <h3>
              Kompletná podpora{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Nielenže navrhneme a vyvineme IT systém, či softvérové riešenie,
              ale tiež poskytneme neustálu podporu po jeho nasadení. Sme tu, aby
              sme zabezpečili hladký chod a úspešnosť vašich IT projektov.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={inovacieIcon}
                alt="Programovanie IT systémov a inovovanie"
                title="Programovanie IT systémov a inovovanie"
              />
            </div>
          </div>
          <div>
            <h3>
              Inovatívne riešenia{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              V oblasti technológie sa neustále meníme. Naši odborníci sú
              neustále v kontakte s novinkami a inováciami, aby sme vám mohli
              prinášať len to najlepšie.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={doveryhodnostIcon}
                alt="Vývoj IT systémov dôvera a dôveryhodnosť"
                title="Vývoj IT systémov dôvera a dôveryhodnosť"
              />
            </div>
          </div>
          <div>
            <h3>
              Dôveryhodnosť{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Naša reputácia hovorí sama za seba. Zoznam spokojných klientov a
              silný záznam úspechov svedčia o tom, že sme dôveryhodným
              partnerom.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={komunikaciaIcon}
                alt="Vývoj a programovanie IT systémov a komunikácia"
                title="Vývoj a programovanie IT systémov a komunikácia"
              />
            </div>
          </div>
          <div>
            <h3>
              Komunikácia{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Odpovedáme rýchlo, jasne a s porozumením. Pre nás je dôležité, aby
              sme boli vždy dostupní a reagovali na vaše požiadavky včas.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={partnerstvoIcon}
                alt="Vývoj IT systémov dlhodobé partnerstvo"
                title="Vývoj IT systémov dlhodobé partnerstvo"
              />
            </div>
          </div>
          <div>
            <h3>
              Dlhodobé partnerstvo{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Veríme v budovanie dlhodobých vzťahov. Naším cieľom je stať sa
              dlhodobým partnerom pre vaše IT potreby, pričom vám poskytneme
              podporu počas celej cesty.
            </p>
          </div>
        </div>
      </section>
      <section className="section-blog">
        <div className="banner-blog">
          <p>
            Ste pripravení na spoluprácu? Poďme sa porozprávať o Vašom projekte!
          </p>
        </div>
      </section>
      <section id="online-business-solutions">
        <h2>Ako budeme postupovať a ako prebieha náš proces?</h2>
        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={analyzaImg}
            alt="Vývoj IT analýza a zber požiadaviek"
            title="Vývoj IT analýza a zber požiadaviek"
          />
          <div className="solution-description">
            <h3>Analýza a špecifikácia požiadaviek</h3>
            <p>
              Stretneme sa s Vami a spoločne určíme Vaše potreby a očakávania.
              Náš tím expertov identifikuje hlavné funkcionálne a nefunkcionálne
              požiadavky, aby sme vytvorili základný návrh a špecifikáciu, ktorá
              bude splňovať Vaše potreby.
            </p>
            {/*<br />
            <p className="bold">
              Prvé stretnutie je na nás a trvá 1 – 2 hodiny
            </p>*/}
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>Dizajn a architektúra</h3>
            <p>
              Na základe stretnutia začneme formovať dizajn a architektúru,
              vyberieme najlepšie technológie a nástroje pre Váš projekt.
              Podrobné návrhy rozhraní a architektúry systému Vám následne
              predložíme na schválenie, predtým ako prejdeme do fázy vývoja.
            </p>
          </div>
          <img
            className="img solution-img"
            src={dizajnImg}
            alt="Vývoj IT návrh a architektúra systému"
            title="Vývoj IT návrh a architektúra systému"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={vyvojImg}
            alt="Proces vývoja a programovania IT systému"
            title="Proces vývoja a programovania IT systému"
          />
          <div className="solution-description">
            <h3>Proces vývoja a programovania</h3>
            <p>
              Začneme programovať, pričom budeme dodržiavať dohodnuté
              špecifikácie a dizajn. Počas tejto fázy budeme uskutočňovať
              periodické kontroly kvality a testy, ktoré zabezpečia, že všetky
              komponenty systému budú správne integrované.
            </p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>Fáza testovania</h3>
            <p>
              Začneme s dôkladným testovaním, kde sa zameriavame na funkčné a
              nefunkčné aspekty. Identifikujeme a odstraňujeme chyby a
              vykonávame testy na bezpečnosť, výkonnosť a kompatibilitu systému,
              aby sme zabezpečili, že je všetko v poriadku.
            </p>
          </div>
          <img
            className="img solution-img"
            src={testovanieImg}
            alt="Vývoj IT fáza testovania"
            title="Vývoj IT fáza testovania"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={nasadenieImg}
            alt="Vývoj IT systému na mieru nasadenie a školenie"
            title="Vývoj IT systému na mieru nasadenie a školenie"
          />
          <div className="solution-description">
            <h3>Nasadenie</h3>
            <p>
              Začneme s inštaláciou a konfiguráciou systému v prostredí, ktoré
              ste nám poskytli. Táto fáza zahrňuje aj skúšobný prevádzkový chod,
              výcvik užívateľov a poskytnutie všetkej potrebnej dokumentácie.
            </p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>Podpora a údržba</h3>
            <p>
              Naším cieľom je zabezpečiť hladký chod systému, preto budeme
              monitorovať systém a riešiť akékoľvek problémy, ktoré by mohli
              vzniknúť. Táto fáza tiež zahrňuje aktualizácie a rozšírenia
              založené na Vašom feedbacku a potrebách, vrátane periodických
              revízií a optimalizácií systému.
            </p>
          </div>
          <img
            className="img solution-img"
            src={podporaImg}
            alt="Vývoj IT a programovanie fáza údržby systému"
            title="Vývoj IT a programovanie fáza údržby systému"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={iteraciaImg}
            alt="Vývoj a programovanie IT systému fáza spätnej väzby"
            title="Vývoj a programovanie IT systému fáza spätnej väzby"
          />
          <div className="solution-description">
            <h3>Fáza spätnej väzby a iterácie</h3>
            <p>
              Spoločne budeme zbierať spätnú väzbu, ktorá nám pomôže prispôsobiť
              a vylepšiť systém. Na základe tejto spätnej väzby budeme plánovať
              a implementovať ďalšie vylepšenia a rozšírenia, aby sme splnili
              všetky Vaše požiadavky.
            </p>
          </div>
        </div>
      </section>

      <References />

      <ZonaClankySlider clanky={pageQuery.data.articles} />

      <section id="zonaFaq">
        <h2>
          Často kladené otázky
          <StaticImage
            className="img"
            src="../../images/new-web/arrow.svg"
            alt="Šípka vpravo"
            placeholder="blurred"
          />
        </h2>

        <p>Časté otázky pri vývoji a programovaní IT systémov:</p>
        <br />
        <br />

        <ZonaFaqQuestion
          question='Čo je to "systém na mieru" a aký je rozdiel oproti štandardnému riešeniu?'
          answer={
            <>
              Systém na mieru je softvérové riešenie navrhnuté a vyvinuté
              špeciálne podľa individuálnych potrieb a požiadaviek klienta, na
              rozdiel od štandardných riešení, ktoré sú určené pre široký trh a
              nemôžu byť plne prispôsobené jednotlivým potrebám.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Koľko času trvá vývoj IT systému na mieru?"
          answer={
            <>
              Dĺžka vývoja závisí od komplexnosti projektu, funkčných a
              nefunkčných požiadaviek. Každý projekt je jedinečný, ale po
              počiatočnej analýze Vám môžeme poskytnúť odhadovaný časový rámec.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako sa stanovuje cena za vývoj na mieru?"
          answer={
            <>
              Cena za vývoj je stanovená na základe komplexnosti, požiadaviek,
              technológií a časového rámca projektu. Pred začiatkom práce Vám
              poskytneme podrobný odhad nákladov.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako je zabezpečená komunikácia počas vývoja?"
          answer={
            <>
              Počas celého vývoja udržujeme pravidelnú komunikáciu so
              zákazníkom, či už prostredníctvom e-mailov, videokonferencií alebo
              osobných stretnutí. Naším cieľom je zaistiť transparentnosť a
              zapojenie klienta do procesu.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Čo ak budem po nasadení systému potrebovať ďalšie úpravy alebo podporu?"
          answer={
            <>
              Ponúkame po-nasadenú podporu a údržbu, ktorá zahŕňa riešenie
              prípadných problémov, aktualizácie a ďalšie úpravy podľa vašich
              potrieb.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako zaistíte bezpečnosť môjho systému?"
          answer={
            <>
              Bezpečnosť je pre nás prioritou. Počas vývoja používame najnovšie
              bezpečnostné protokoly a postupy, aby sme zabezpečili ochranu dát
              a systému pred hrozbami.
            </>
          }
        />
      </section>
      <ContactUs
        text={<>Máte záujem o vývoj a programovanie IT systémov?</>}
        button="Napíšte nám e-mail"
      />
    </Layout>
  );
};

export default VyvojItSystemov;
